import React, { useState } from "react";
import { motion } from "framer-motion";
import { useTranslation } from 'react-i18next';
import ShareLinks from "../components/ShareLinks";

const containerVariants = {
  hidden: {
    opacity: 0,
    x: "100vw",
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: { type: "spring", delay: 0.1 },
  },
  exit: {
    x: "-100vh",
    transition: { ease: "easeInOut" },
  },
};

export default function About() {
  const { t } = useTranslation();

  return (
    <motion.div
      className="base container"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <h1>{t('About')}</h1>
      <h2>{t('Contact')}</h2>
      <h1><a href="mailto:support@logicgames.app">{t('Email')}</a></h1>
      <h3>{t('Follow')}</h3>
      <h3>{t('Share')}</h3>
      <ShareLinks />
    </motion.div>
  );
}
