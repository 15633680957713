import styled from 'styled-components';

export const StyledStage = styled.div`
  display: grid;
  grid-template-rows: repeat(
    ${props => props.height},
    calc(25vw / ${props => props.width})
  );
  grid-template-columns: repeat(${props => props.width}, 1fr);
  grid-gap: 1px;
  border: 2px solid #a7a7a7;
  width: 100%;   
  max-width: 25vw;
  background: #ddd;
  @media (max-width: 1100px) {
    grid-template-rows: repeat(
      ${props => props.height},
      calc(50vw / ${props => props.width})
    );
    max-width: 50vw;
  }
  @media (max-width: 840px) {
    grid-template-rows: repeat(
      ${props => props.height},
      calc(55vw / ${props => props.width})
    );
    max-width: 55vw;
  }
  @media (max-width: 640px) {
    grid-template-rows: repeat(
      ${props => props.height},
      calc(65vw / ${props => props.width})
    );
    max-width: 65vw;
    margin: 0 auto;
  }
`;
