import React from 'react';
import styled from 'styled-components';

const StyledStartButton = styled.button`
  box-sizing: border-box;
  margin: 0 0 20px 0;
  padding: 0 15px;
  min-height: 40px;
  width: 100%;
  border-radius: 5px;
  border: none;
  color: white;
  background: #f2f2f2;
  font-family: Pixel, Arial, Helvetica, sans-serif;
  font-size: 1rem;
  outline: none;
  cursor: pointer;
  color: #333;
  line-height: 1rem;
  &:hover {
      font-size: 1rem;
  }
  @media (max-width: 640px) {
    font-size: 14px;
    line-height: 14px;
    padding: 0 10px;
    margin: 0 0 10px 0;
    min-height: 35px;
  }
`;

const StartButton = ({ callback }) => (
  <StyledStartButton onClick={callback}>Play</StyledStartButton>
);

export default StartButton;
