let bs = {
  translation: {
    "Welcome to React": "Welcome to React and react-i18next bs",
    "Sidebar title": "Igrice",
    "Header title": "Igrice puzle",
    "Tetris": "Puzle",
    "Connect4": "Spoji 4 boje",
    "Tic tac toe": "Iks Oks",
    "Minesweeper": "Minsko polje",
    "Connect cards": "Spoji kartice",
    "Squares": "Složi kocku",
    "Masetrmind": "Mastermind",
    "Score": "Rezultat",
    "Rows": "redovi",
    "Play": "Igraj",
    "Level": "Nivo",
    "Result": "Rezultat",
    "Reset": "Reset",
    "Single player": "Jedan igrač",
    "Multi player": "Dva igrača",
    "Squares info": "Klikni na kockicu da je pomjeriš (samo kockice koje su do 0)",
    "New 3x3 game": "Nova 3x3 igra",
    "New 4x4 game": "Nova 5x5 igra",
    "New 5x5 game": "Nova 5x5 igra",
    "English": "Engleski",
    "Bosnian": "Bosanski",
    "German": "Njemački",
    "Show rules": "Pokaži pravila",
    "Hide rules": "Sakrij pravila",
    "Rules": "Pokušajte pogoditi uzorak, i redom i bojom, iz deset pokušaja. Nakon popunjavanja reda, prikazuje se mali zeleni kvadrat za svaki krug u ispravnom položaju i boji. Žuti kvadrat označava postojanje ispravne boje u pogrešnom položaju.",
    "More info": "Više informacija na",
    "Color Match": "Slaganje boja",
    "Color match title": "pronađi odgovarajući par boja da bi pobjedio.",
    "New game": "Nova igra",
    "Game in progress": "Igra u toku",
    "Mines remaining": "Preostalo mina:",
    "You Lost": "Izgubio si",
    "Game over": "Igra je završena",
    "You Win": "Pobjedio si",
    "Won": "Pobjednik",
    "check": "provjeri",
    "Solution": "Rješenje",
    "Play again": "Igraj ponovo",
    "About": "Hvala vam što ste posjetili apliakciju logicgames.app",
    "Contact": "Kontaktirajte nas na e-mail za bilo kakva pitanja i informacije",
    "Email": "support@logicgames.app",
    "Follow": "Pratite nas na facebook-u i instagramu",
    "Share": "Bićemo vam zahvalni ako podijelite aplikaciju sa svojim prijateljim.",
    "Mobile title": "Snimite aplikaciju na mobitel i igrajte igrice bez interneta",
    "Download": "Preuzmite"
  },
};

export default bs;
