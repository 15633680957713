import React, { Component } from "react";
import shuffle from "shuffle-array";
import { CardState } from "../components/connectcards/CardColors";
import Cards from "../components/connectcards/CardColors";
import LandingPage from "../components/connectcards/LandingPage";
import CardList from "../components/connectcards/CardList";
import WinMessage from "../components/connectcards/WinMessage";
import "../styles/connectcards.css";
import { motion } from "framer-motion";
import ReklamaHorizontal from "../components/oglasi/ReklamaHorizontal";
import ReklamaHorizontalMob from "../components/oglasi/ReklamaHorizontalMob";
import ReklamaVetikal from "../components/oglasi/ReklamaVetikal";
import ShareLinks from "../components/ShareLinks";

const containerVariants = {
  hidden: {
    opacity: 0,
    x: "100vw",
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: { type: "spring" },
  },
  exit: {
    x: "-100vh",
    transition: { ease: "easeInOut" },
  },
};

class ConnectCards extends Component {
  constructor(props) {
    super(props);

    let ImportedCards = Cards();
    let cards = shuffle(ImportedCards);

    this.state = {
      cards,
      noClick: false,
      showStart: true,
      showCardList: false,
      winMessage: false,
      title: 'Play'
    };

    this.timeEndgame = null;
    this.timer = null;
  }

  handleStart = (title) => {
    this.setState({ showStart: false, showCardList: true, winMessage: false, title: title });
  };

  handleClick = (id) => {
    const mapCardState = (cards, idsToChange, newCardState) => {
      return cards.map((card) => {
        if (idsToChange.includes(card.id)) {
          return {
            ...card,
            cardState: newCardState,
          };
        }
        return card;
      });
    };

    const foundCard = this.state.cards.find((c) => c.id === id);

    if (this.state.noClick || foundCard.cardState !== CardState.HIDING) {
      return;
    }

    let noClick = false;

    let cards = mapCardState(this.state.cards, [id], CardState.SHOWING);

    const showingCards = cards.filter(
      (card) => card.cardState === CardState.SHOWING
    );

    const ids = showingCards.map((card) => card.id);

    if (
      showingCards.length === 2 &&
      showingCards[0].backgroundColor === showingCards[1].backgroundColor
    ) {
      cards = mapCardState(cards, ids, CardState.MATCHING);
    } else if (showingCards.length === 2) {
      let hidingCards = mapCardState(cards, ids, CardState.HIDING);

      noClick = true;

      this.setState({ cards, noClick }, () => {
        this.timer = setTimeout(() => {
          this.setState({ cards: hidingCards, noClick: false });
        }, 1200);
      });
      return;
    }
    this.setState({ cards, noClick });

    let unmatched = this.state.cards.filter(
      (c) => c.cardState === CardState.HIDING
    );

    if (unmatched.length === 1) {
      this.timeEndgame = setTimeout(() => {
        this.setState({
          showCardList: false,
          showStart: false,
          winMessage: true,
        });
      }, 3000);
    }
  };

  handleNewGame = () => {
    let cards = this.state.cards.map((card) => ({
      ...card,
      cardState: CardState.HIDING,
    }));
    cards = shuffle(cards);
    this.setState({
      cards,
      showCardList: true,
      showStart: false,
      winMessage: false,
    });
  };

  componentWillUnmount() {
    clearTimeout(this.timer);
    clearTimeout(this.timeEndgame);
  }

  render() {
    return (
      <motion.div
        className="base container"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <div className="ConnectCards">
          <div>
          <ReklamaHorizontal />
          <ReklamaHorizontalMob />
          </div>
          <div>
            {this.state.showStart && (
              <LandingPage handleStart={this.handleStart} />
            )}
            {this.state.showCardList && (
              <>
                <button onClick={this.handleNewGame}>Reset</button>
                <CardList
                  cards={this.state.cards}
                  handleClick={this.handleClick}
                />
              </>
            )}
            {this.state.winMessage && <WinMessage />}
          </div>
          <div>
          <ReklamaVetikal />
          </div>
          <ShareLinks />
        </div>
      </motion.div>
    );
  }
}

export default ConnectCards;
