import React, { useState } from "react";
import TetrisGame from "../components/tetris/Tetris";
import { motion } from "framer-motion";
import ReklamaHorizontal from "../components/oglasi/ReklamaHorizontal";
import ReklamaHorizontalMob from "../components/oglasi/ReklamaHorizontalMob";
import ReklamaVetikal from "../components/oglasi/ReklamaVetikal";
import ShareLinks from "../components/ShareLinks";

const containerVariants = {
  hidden: {
    opacity: 0,
    x: "100vw",
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: { type: "spring", delay: 0.1 },
  },
  exit: {
    x: "-100vh",
    transition: { ease: "easeInOut" },
  },
};

export default function Tetris() {
  return (
    <motion.div
      className="base container"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <div className="App Tetris-Game">
        <div>
          <ReklamaHorizontal />
          <ReklamaHorizontalMob />
        </div>
        <TetrisGame />
        <div>
          <ReklamaVetikal />
        </div>
        <ShareLinks />
      </div>
    </motion.div>
  );
}
