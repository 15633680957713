let de = {
  translation: {
    "Welcome to React": "Welcome to React and react-i18next de",
    "Sidebar title": "Spiele",
    "Header title": "Logik-Puzzlespiele",
    "Tetris": "Puzzle",
    "Connect4": "Verbinden Sie vier in Reihe",
    "Tic tac toe": "Tic tac toe",
    "Minesweeper": "Minensuchboot",
    "Connect cards": "Karten anschließen",
    "Squares": "Squares",
    "Masetrmind": "Masetrmind",
    "Score": "Ergebnis",
    "Rows": "Reihen",
    "Play": "Abspielen",
    "Level": "Niveau",
    "Result": "Ergebnis",
    "Reset": "Zurücksetzen",
    "Single player": "Einzelspieler",
    "Multi player": "Multiplayer",
    "Squares info": "Klicken Sie auf die Quadrate, um sie zu verschieben (nur die neben dem 0-Quadrat).",
    "New 3x3 game": "Neues 3x3 Spiel",
    "New 4x4 game": "Neues 4x4 Spiel",
    "New 5x5 game": "Neues 5x5 Spiel",
    "English": "Englisch",
    "Bosnian": "Bosnisch",
    "German": "Deutsche",
    "Show rules": "Regeln anzeigen",
    "Hide rules": "Regeln ausblenden",
    "Rules": "Versuchen Sie, das Muster innerhalb von zehn Umdrehungen in Reihenfolge und Farbe zu erraten. Nach dem Einreichen einer Zeile wird für jeden Kreis ein kleines grünes Quadrat an der richtigen Position und Farbe angezeigt. Ein gelbes Quadrat zeigt das Vorhandensein einer korrekten Farbe an einer falschen Position an.",
    "More info": "Weitere Infos zu",
    "Color Match": "Color Match",
    "Color match title": "Find the correct pairs of colours to win the game.",
    "New game": "New game",
    "Game in progress": "Gerade stattfindendes Spiel",
    "Mines remaining": "Minen übrig:",
    "You Lost": "Du hast verloren",
    "Game over": "Spiel ist aus",
    "You Win": "Du gewinnst",
    "Won": "Gewonnen",
    "check": "prüfen",
    "Solution": "Lösung",
    "Play again": "Nochmal abspielen",
    "About": "Danke für Ihren Besuch logicgames.app",
    "Contact": "Wenn Sie Fragen oder Tipps haben, können Sie uns per E-Mail kontaktieren",
    "Email": "support@logicgames.app",
    "Follow": "Bitte folgen Sie uns auf Facebook und Instagram",
    "Share": "Wir würden uns freuen, wenn Sie die App mit Ihren Freunden teilen",
    "Mobile title": "Laden Sie die App für Ihr Handy herunter und spielen Sie offline",
    "Download": "Download"
  },
};

export default de;
