import React from "react";
import { useTranslation } from 'react-i18next';

const LandingPage = ({handleStart}) => {
  const { t } = useTranslation();
  return (
    <div className='landing'>
      <h1>{t('Color Match')}</h1>
      <p><i>{t('Color match title')}</i></p>
      <button onClick={() => handleStart(t('New game'))}>{t('Play')}</button>
    </div>
  );
};

export default LandingPage;
  