import React from "react";

export default function ReklamaHorizontal() {
  return (
    <div style={{display: 'none'}}>
    <img
      className="ReklamaHorizontal"
      src="https://via.placeholder.com/700x100?text=Visit+Blogging.com+Now"
    />
    </div>
  );
}
