import React, { useState, useEffect } from "react";
import "../styles/tictactoe.css";
import { motion } from "framer-motion";
import { useTranslation } from 'react-i18next';
import ReklamaHorizontal from "../components/oglasi/ReklamaHorizontal";
import ReklamaHorizontalMob from "../components/oglasi/ReklamaHorizontalMob";
import ReklamaVetikal from "../components/oglasi/ReklamaVetikal";
import ShareLinks from "../components/ShareLinks";

const containerVariants = {
  hidden: {
    opacity: 0,
    x: "100vw",
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: { type: "spring" },
  },
  exit: {
    x: "-100vh",
    transition: { ease: "easeInOut" },
  },
};

export default function TicTacToe() {
  const { t } = useTranslation();
  const [vsPC, setVsPC] = useState(null);
  const [player_one_symbol, setPlayer_one_symbol] = useState("X");
  const [player_two_symbol, setPlayer_two_symbol] = useState("O");
  const [x_turn, setX_turn] = useState(true);
  const [board, setBoard] = useState(["", "", "", "", "", "", "", "", ""]);
  const [count, setCount] = useState(0);
  const [havewinner, setHavewinner] = useState("");

  const handleCellClick = (index, keep_playing) => {
    setCount(count + 1);
    if (board[index] === "" && keep_playing === true && vsPC !== null) {
      let update_board = board;
      if (vsPC === false) {
        let symbol = x_turn ? player_one_symbol : player_two_symbol;
        let next_turn = !x_turn;
        update_board[index] = symbol;
        setBoard(update_board);
        setX_turn(next_turn);
      }
      if (vsPC === true) {
        update_board[index] = player_one_symbol;
        setBoard(update_board);
        let ai_index = find_best_move(update_board);
        if (ai_index !== -4) update_board[ai_index] = player_two_symbol;
        setBoard(update_board);
      }
    }
  };

  const handleResetButton = () => {
    setBoard(["", "", "", "", "", "", "", "", ""]);
    setX_turn(true);
    setVsPC(null);
    setHavewinner("");
  };

  let keep_playing = true;

  const handleSinglePlayerButton = () => {
    setBoard(["", "", "", "", "", "", "", "", ""]);
    setX_turn(true);
    setVsPC(true);
    setHavewinner("");
  };

  const handleMultiPlayerButton = () => {
    setBoard(["", "", "", "", "", "", "", "", ""]);
    setX_turn(true);
    setVsPC(false);
    setHavewinner("");
  };

  const winner = (squares) => {
    const lines = [
      [0, 1, 2],
      [3, 4, 5],
      [6, 7, 8],
      [0, 3, 6],
      [1, 4, 7],
      [2, 5, 8],
      [0, 4, 8],
      [2, 4, 6],
    ];

    for (let i = 0; i < lines.length; i++) {
      let [a, b, c] = lines[i];
      if (
        squares[a] !== "" &&
        squares[a] === squares[b] &&
        squares[a] === squares[c] &&
        squares[b] === squares[c]
      )
        return squares[a];
    }

    return null;
  };

  const arrayToMat = (squares) => {
    let mat = [];
    let k = 0;

    for (let i = 0; i < 3; i++) {
      mat[i] = [];
      for (let j = 0; j < 3; j++) mat[i][j] = squares[k++];
    }

    return mat;
  };

  const hasMovesLeft = (mat) => {
    for (let i = 0; i < 3; i++) {
      for (let j = 0; j < 3; j++) {
        if (mat[i][j] === "") {
          return true;
        }
      }
    }

    return false;
  };

  const evaluate = (mat, depth) => {
    for (let i = 0; i < 3; i++) {
      if (
        mat[i][0] === mat[i][1] &&
        mat[i][0] === mat[i][2] &&
        mat[i][1] === mat[i][2]
      ) {
        if (mat[i][0] === "O") return 100 - depth;
        if (mat[i][0] === "X") return depth - 100;
      }
    }
    for (let j = 0; j < 3; j++) {
      if (
        mat[0][j] === mat[1][j] &&
        mat[0][j] === mat[2][j] &&
        mat[1][j] === mat[2][j]
      ) {
        if (mat[0][j] === "O") return 100 - depth;
        if (mat[0][j] === "X") return depth - 100;
      }
    }
    if (
      mat[0][0] === mat[1][1] &&
      mat[0][0] === mat[2][2] &&
      mat[1][1] === mat[2][2]
    ) {
      if (mat[0][0] === "O") return 100 - depth;
      if (mat[0][0] === "X") return depth - 100;
    }
    if (
      mat[0][2] === mat[1][1] &&
      mat[0][2] === mat[2][0] &&
      mat[1][1] === mat[2][0]
    ) {
      if (mat[0][2] === "O") return 100 - depth;
      if (mat[0][2] === "X") return depth - 100;
    }
    return 0;
  };

  const minmax = (mat, depth, get_max) => {
    if (hasMovesLeft(mat) === false) {
      return evaluate(mat, depth);
    }
    let val = evaluate(mat, depth);
    if (val !== 0) return val;
    if (get_max) {
      let best = -Infinity;
      for (let i = 0; i < 3; i++) {
        for (let j = 0; j < 3; j++) {
          if (mat[i][j] === "") {
            mat[i][j] = "O";
            best = Math.max(best, minmax(mat, depth + 1, !get_max));
            mat[i][j] = "";
          }
        }
      }
      return best;
    } else {
      let best = Infinity;
      for (let i = 0; i < 3; i++) {
        for (let j = 0; j < 3; j++) {
          if (mat[i][j] === "") {
            mat[i][j] = "X";
            best = Math.min(best, minmax(mat, depth + 1, !get_max));
            mat[i][j] = "";
          }
        }
      }
      return best;
    }
  };

  const find_best_move = (squares) => {
    let mat = arrayToMat(squares);
    let val,
      row = -1,
      col = -1,
      best = -Infinity;

    for (let i = 0; i < 3; i++) {
      for (let j = 0; j < 3; j++) {
        if (mat[i][j] === "") {
          mat[i][j] = "O";
          val = minmax(mat, 0, false);
          mat[i][j] = "";

          if (val > best) {
            best = val;
            row = i;
            col = j;
          }
        }
      }
    }
    return 3 * row + col;
  };

  useEffect(() => {
    let have_winner = winner(board);
    keep_playing = have_winner === null ? true : false;
    if (have_winner !== null) setHavewinner(have_winner + " " + t('Won'));
  }, [count]);

  return (
    <motion.div
      className="base container"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <div className="Tictactoe-wrapper">
        <div>
         <ReklamaHorizontal />
          <ReklamaHorizontalMob />
        </div>
        <div className="master">
          <div className="result-box">
            {/* <div className="result">Rezultat 2 : 0</div> */}
            <div className="winner">{havewinner}</div>
          </div>
          <div className="tic-game">
            <div className="tic-board">
              {board.map((cell, index) => {
                return (
                  <div
                    className="tic-square"
                    key={index}
                    onClick={() => handleCellClick(index, keep_playing)}
                  >
                    {" "}
                    {cell}{" "}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="side-bar">
            <div className="reset-button" onClick={handleResetButton}>
              {" "}
              {t('Reset')}{" "}
            </div>
            <div
              className={
                vsPC === true
                  ? "active-player-button"
                  : "deactived-player-button"
              }
              onClick={handleSinglePlayerButton}
            >
              {" "}
              {t('Single player')}{" "}
            </div>
            <div
              className={
                vsPC === false
                  ? "active-player-button"
                  : "deactived-player-button"
              }
              onClick={handleMultiPlayerButton}
            >
              {" "}
              {t('Multi player')}{" "}
            </div>
          </div>
        </div>
        <div>
          <ReklamaVetikal />
        </div>
        <ShareLinks />
      </div>
    </motion.div>
  );
}
