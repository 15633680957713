let en = {
  translation: {
    "Welcome to React": "Welcome to React and react-i18next",
    "Sidebar title": "Games",
    "Header title": "Logic puzzle games",
    "Tetris": "Puzzle",
    "Connect4": "Connect four",
    "Tic tac toe": "Tic tac toe",
    "Minesweeper": "Minesweeper",
    "Connect cards": "Connect the cards",
    "Squares": "Squares",
    "Masetrmind": "Mastermind",
    "Score": "Score",
    "Rows": "Rows",
    "Play": "Play",
    "Level": "Level",
    "Result": "Result",
    "Reset": "Reset",
    "Single player": "Single player",
    "Multi player": "Multi player",
    "Squares info": "Click on the squares to move them (only those next to the 0 square)",
    "New 3x3 game": "New 3x3 game",
    "New 4x4 game": "New 5x5 game",
    "New 5x5 game": "New 5x5 game",
    "English": "English",
    "Bosnian": "Bosnian",
    "German": "German",
    "Show rules": "Show Rules",
    "Hide rules": "Hide Rules",
    "Rules": "Try to guess the pattern, in both order and color, within ten turns. After submitting a row, a small green squared is show for each circle in a correct position and color. A yellow square indicates the existence of a correct color in an incorrect position.",
    "More info": "More info on",
    "Color Match": "Color Match",
    "Color match title": "Find the correct pairs of colours to win the game.",
    "New game": "New game",
    "Game in progress": "Game in progress",
    "Mines remaining": "Mines remaining:",
    "You Lost": "You Lost",
    "Game over": "Game over",
    "You Win": "You Win",
    "Won": "Won",
    "check": "check",
    "Solution": "Solution",
    "Play again": "Play again",
    "About": "Thank you for visiting logicgames.app",
    "Contact": "If you have any questions or tips you can contact us at email",
    "Email": "support@logicgames.app",
    "Follow": "Please follow us on facebook and instagram",
    "Share": "We would appreciate it if you share the app with your friends",
    "Mobile title": "Download app for your mobile and play offline",
    "Download": "Download"
  },
};

export default en;
