import React, { useState } from "react";
import { Rules } from "../components/mastermind/rules";
import { Game } from "../components/mastermind/game";
import "../styles/mastermind.css";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import ReklamaHorizontal from "../components/oglasi/ReklamaHorizontal";
import ReklamaHorizontalMob from "../components/oglasi/ReklamaHorizontalMob";
import ReklamaVetikal from "../components/oglasi/ReklamaVetikal";
import ShareLinks from "../components/ShareLinks";

const containerVariants = {
  hidden: {  
    opacity: 0,
    x: "100vw",
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: { type: "spring" },
  },
  exit: {
    x: "-100vh",
    transition: { ease: "easeInOut" },
  },
};

export default function Mastermind() {
  const { t } = useTranslation();

  return (
    <motion.div
      className="base container"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <div className="Mastermind-wrapper">
        <div>
          <ReklamaHorizontal />
          <ReklamaHorizontalMob />
        </div>
        <Game showTitle={t('Show rules')} hideTitle={t('Hide rules')} rules={t('Rules')} wonMsg={t('You Win')} lostMsg={t('You Lost')} />
        <div>
        <ReklamaVetikal />
        </div>
        <ShareLinks />
      </div>
    </motion.div>
  );
}
