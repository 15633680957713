import React, { Component } from "react";
import "../styles/squares.css";
import { motion } from "framer-motion";
import { Translation } from 'react-i18next';
import ReklamaHorizontal from "../components/oglasi/ReklamaHorizontal";
import ReklamaHorizontalMob from "../components/oglasi/ReklamaHorizontalMob";
import ReklamaVetikal from "../components/oglasi/ReklamaVetikal";
import ShareLinks from "../components/ShareLinks";

const containerVariants = {
  hidden: {
    opacity: 0,
    x: "100vw",
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: { type: "spring" },
  },
  exit: {
    x: "-100vh",
    transition: { ease: "easeInOut" },
  },
};

export default class Squares extends Component {
  constructor(props) {
    super(props);
    this.state = { board: [1, 2, 3, 4, 5, 6, 7, 8, 0], size: 3 };
  }
  newGame(size) {
    let board = new Array(size * size);
    for (let i = 0; i < size * size; ++i) board[i] = i;
    board = this.shuffle(board);
    this.updateBoard(board, size);
    this.setState({ size: size });
  }
  updateBoard(board, size) {
    this.setState({ board: board });
  }
  shuffle(o) {
    const temp = o.slice();
    for (
      var j, x, i = temp.length;
      i;
      j = Math.floor(Math.random() * i),
        x = temp[--i],
        temp[i] = temp[j],
        temp[j] = x
    );
    return temp;
  }
  render() {
    return (
      <motion.div
        className="base container"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <div className="Puzzle">
          <div>
          <ReklamaHorizontal />
          <ReklamaHorizontalMob />
          </div>
          <div className="Puzzle-content">
            <div className="Puzzle-info">
            <Translation>
              {
                t => <div className="Squares-info">{t('Squares info')}</div>
              }
            </Translation>
            </div>
            {this.state && this.state.board ? (
              <Board
                size={this.state.size}
                board={this.state.board}
                updateBoard={this.updateBoard.bind(this)}
              />
            ) : null}
            <div className="Puzzle-buttons">
            <Translation>
              {
                t =>  <input type="submit" value={t('New 3x3 game')} onClick={this.newGame.bind(this, 3)} />
              }
            </Translation>
            <Translation>
              {
                t =>  <input type="submit" value={t('New 4x4 game')} onClick={this.newGame.bind(this, 4)} />
              }
            </Translation>
            <Translation>
              {
                t =>  <input type="submit" value={t('New 5x5 game')} onClick={this.newGame.bind(this, 5)} />
              }
            </Translation>
            </div>
          </div>
          <div>
          <ReklamaVetikal />
          </div>
          <ShareLinks />
        </div>
      </motion.div>
    );
  }
}

class Board extends Component {
  componentWillMount() {
    this.findClickables(this.props.board, this.props.size);
  }
  componentWillReceiveProps(nextProps) {
    this.findClickables(nextProps.board, nextProps.size);
  }
  shouldComponentUpdate(nextProps) {
    const curr = this.props.board.join("");
    const next = nextProps.board.join("");
    return curr !== next;
  }

  findClickables(board, size) {
    const zeroIndex = board.indexOf(0);
    const zeroCoordinate = this.getCoordFromIndex(zeroIndex, size);
    const possibleTopIdx =
      zeroCoordinate.row > 0
        ? this.getIndexFromCoord(
            zeroCoordinate.row - 1,
            zeroCoordinate.column,
            size
          )
        : null;
    const possiblRightIdx =
      zeroCoordinate.column < size
        ? this.getIndexFromCoord(
            zeroCoordinate.row,
            zeroCoordinate.column + 1,
            size
          )
        : null;
    const possiblBottomIdx =
      zeroCoordinate.row < size
        ? this.getIndexFromCoord(
            zeroCoordinate.row + 1,
            zeroCoordinate.column,
            size
          )
        : null;
    const possibleLeftIdx =
      zeroCoordinate.column > 0
        ? this.getIndexFromCoord(
            zeroCoordinate.row,
            zeroCoordinate.column - 1,
            size
          )
        : null;

    this.setState({
      zero: zeroIndex,
      possibleTopIdx: possibleTopIdx,
      possiblRightIdx: possiblRightIdx,
      possiblBottomIdx: possiblBottomIdx,
      possibleLeftIdx: possibleLeftIdx,
    });
  }
  getCoordFromIndex(idx, size) {
    return { row: Math.floor(idx / size) + 1, column: (idx % size) + 1 };
  }
  getIndexFromCoord(row, col, size) {
    return size * (row - 1) + col - 1;
  }
  cellClickHandler(index) {
    if (
      index === this.state.possibleTopIdx ||
      index === this.state.possiblRightIdx ||
      index === this.state.possiblBottomIdx ||
      index === this.state.possibleLeftIdx
    )
      this.nextBoard(index);
  }
  nextBoard(index, direction) {
    const board = this.props.board.slice();
    const temp = board[index];
    board[index] = board[this.state.zero];
    board[this.state.zero] = temp;
    this.props.updateBoard(board);
  }
  render() {
    const squares = this.props.board.map((val, index) => {
      if ((index + 1) % this.props.size === 0) {
        return (
          <span>
            {
              <Cell
                value={val}
                clickHandler={this.cellClickHandler.bind(this, index)}
              />
            }
            <br />
          </span>
        );
      }
      return (
        <Cell
          value={val}
          clickHandler={this.cellClickHandler.bind(this, index)}
        />
      );
    });
    return <div className="board-box">{squares}</div>;
  }
}

class Cell extends Component {
  render() {
    const cls = this.props.value === 0 ? "square zero" : "square";
    return (
      <span className={cls} onClick={() => this.props.clickHandler()}>
        {this.props.value}
      </span>
    );
  }
}
