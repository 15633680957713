import React, { useState } from "react";
import { Link } from "react-router-dom";
import RubberBand from "react-reveal/RubberBand";
import { motion } from "framer-motion";
import tetrisimg from "../images/games/tetris.png";
import tictactoeimg from "../images/games/tictactoe.png";
import connect4img from "../images/games/connect4.png";
import mastermindimg from "../images/games/mastermind.png";
import squaresimg from "../images/games/squares.png";
import cardsimg from "../images/games/cards.png";
import minesweeperimg from "../images/games/minesweeper.png";
import Zoom from "react-reveal/Zoom";
import { useTranslation } from "react-i18next";
import ShareLinks from "../components/ShareLinks";

const containerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { delay: 0.1, duration: 0.3 },
  },
  exit: {
    x: "-100vh",
    transition: { ease: "easeInOut" },
  },
};

export default function Home() {
  const { t } = useTranslation();
  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <Zoom>
        <div className="HomeGame">
          <div>
            <div className="HomegameCard">
              <Link to="/conncet-four">
                <img src={connect4img} />
                <div>{t("Connect4")}</div>
              </Link>
            </div>
            <div className="HomegameCard">
              <Link to="/squares">
                <img src={squaresimg} />
                <div>{t("Squares")}</div>
              </Link>
            </div>
            <div className="HomegameCard">
              <Link to="/tic-tac-toe">
                <img src={tictactoeimg} />
                <div>{t("Tic tac toe")}</div>
              </Link>
            </div>
          </div>
          <div>
            {/* <div className="HomegameCard big-img">
              <Link to="/puzzle">
                <img src={tetrisimg} />
                <div>{t("Tetris")}</div>
              </Link>
            </div> */}
            <div className="HomegameCard">
              <Link to="/minesweeper">
                <img src={minesweeperimg} />
                <div>{t("Minesweeper")}</div>
              </Link>
            </div>
            <div className="HomegameCard big-img">
              <Link to="/mastermind">
                <img src={mastermindimg} />
                <div>{t("Mastermind")}</div>
              </Link>
            </div>
            <div className="HomegameCard big-img">
              <Link to="/cards">
                <img src={cardsimg} />
                <div>{t("Conect cards")}</div>
              </Link>
            </div>
          </div>
          <ShareLinks />
        </div>
      </Zoom>
    </motion.div>
  );
}
