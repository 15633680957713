import React from "react";
import {useLocation} from "react-router-dom";
import {StickyShareButtons} from 'sharethis-reactjs';

const ShareLinks = () => {
 let location = useLocation();
 let url = 'logicgames.app' + location.pathname;

  return (
    <div>
     <StickyShareButtons
        config={{
          alignment: 'right',    // alignment of buttons (left, right)
          color: 'white',      // set the color of buttons (social, white)
          enabled: true,        // show/hide buttons (true, false)
          font_size: 14,        // font size for the buttons
          hide_desktop: false,  // hide buttons on desktop (true, false)
          labels: 'null',     // button labels (cta, counts, null)
          language: 'en',       // which language to use (see LANGUAGES)
          min_count: 20,         // hide react counts less than min_count (INTEGER)
          networks: [           // which networks to include (see SHARING NETWORKS)
            'facebook',
            'twitter',
            'pinterest',
            'whatsapp',
            'email',
            'reddit',
            'linkedin'
          ],
          padding: 8,          // padding within buttons (INTEGER)
          radius: 4,            // the corner radius on each button (INTEGER)
          show_total: false,     // show/hide the total share count (true, false)
          show_mobile: true,    // show/hide the buttons on mobile (true, false)
          show_toggle: true,    // show/hide the toggle buttons (true, false)
          size: 36,             // the size of each button (INTEGER)
          top: 180,             // offset in pixels from the top of the page

          // OPTIONAL PARAMETERS
          url: url, // (defaults to current url)
          image: 'https://logicgames.app/lg.png',  // (defaults to og:image or twitter:image)
          description: 'Logic puzzle games',       // (defaults to og:description or twitter:description)
          title: 'Please visit site: ' + url,            // (defaults to og:title or twitter:title)
          message: 'Please visit site: ' + url,     // (only for email sharing)
          subject: 'Share web site logicgames.app',  // (only for email sharing)
         // username: 'custom twitter handle' // (only for twitter sharing)

        }}
      />
    </div>
  );
};

export default ShareLinks;
