import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import InfoIcon from "@material-ui/icons/Info";
import HomeIcon from "@material-ui/icons/Home";
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import Zoom from "react-reveal/Zoom";
import { useTranslation } from "react-i18next";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 10,
    marginRight: 10,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: 0,
    color: "#fff",
    marginRight: 5
  },
}));

export default function Navbar() {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const [lang, setLang] = useState("en");

  const handleChange = (event) => {
    const selectLang = event.target.value;
    console.log(selectLang);
    setLang(selectLang);
    i18n.changeLanguage(selectLang);
  };

  return (
    <ul className="navbar">
      <Zoom>
        <li>
          <FormControl className={classes.formControl}>
            <Select
              value={lang}
              onChange={handleChange}
              className={classes.selectEmpty}
            >
              <MenuItem value="en">English</MenuItem>
              <MenuItem value="bs">Bosanski</MenuItem>
              <MenuItem value="de">Deutsche</MenuItem>
            </Select>
          </FormControl>
        </li>
      </Zoom>
      <Zoom>
        <li>
          <Link to="/">
            <HomeIcon></HomeIcon>
          </Link>
        </li>
      </Zoom>
      <Zoom>
        <li>
          <Link to="/mobile">
            <PhoneIphoneIcon></PhoneIphoneIcon>
          </Link>
        </li>
      </Zoom>
      <Zoom>
        <li>
          <Link to="/about">
            <InfoIcon></InfoIcon>
          </Link>
        </li>
      </Zoom>
    </ul>
  );
}
