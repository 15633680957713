import React, { Component, useState, useEffect } from "react";
import "../styles/connect4.css";
import { motion } from "framer-motion";
import { useTranslation } from 'react-i18next';
import ReklamaHorizontal from "../components/oglasi/ReklamaHorizontal";
import ReklamaHorizontalMob from "../components/oglasi/ReklamaHorizontalMob";
import ReklamaVetikal from "../components/oglasi/ReklamaVetikal";
import ShareLinks from "../components/ShareLinks";

const containerVariants = {
  hidden: {
    opacity: 0,
    x: "100vw",
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: { type: "spring" },
  },
  exit: {
    x: "-100vh",
    transition: { ease: "easeInOut" },
  },
};

function Hole(props) {
  return (
    <div className="Hole">
      <div className={props.value}></div>
    </div>
  );
}

function Slat(props) {
  return (
    <div className="Slat" onClick={() => props.handleClick()}>
      {[...Array(props.holes.length)].map((x, j) => (
        <Hole key={j} value={props.holes[j]}></Hole>
      ))}
    </div>
  );
}

class Board extends Component {
  constructor() {
    super();
    this.state = {
      boardState: new Array(7).fill(new Array(6).fill(null)),
      playerTurn: "Red",
      gameMode: "multi",
      gameSelected: true,
      winner: "",
    };
  }

  selectedGame(mode, e) {
    e.preventDefault();
    this.setState({
      gameMode: mode,
      gameSelected: true,
      boardState: new Array(7).fill(new Array(6).fill(null)),
    });
  }

  makeMove(slatID) {
    const boardCopy = this.state.boardState.map(function (arr) {
      return arr.slice();
    });
    if (boardCopy[slatID].indexOf(null) !== -1) {
      let newSlat = boardCopy[slatID].reverse();
      newSlat[newSlat.indexOf(null)] = this.state.playerTurn;
      newSlat.reverse();
      this.setState({
        playerTurn: this.state.playerTurn === "Red" ? "Blue" : "Red",
        boardState: boardCopy,
      });
    }
  }

  /*Only make moves if winner doesn't exist*/
  handleClick(slatID) {
    if (this.state.winner === "") {
      this.makeMove(slatID);
    }
  }

  componentDidUpdate() {
    let winner = checkWinner(this.state.boardState);
    if (this.state.winner !== winner) {
      this.setState({ winner: winner });
    } else {
      if (
        this.state.gameMode === "single" &&
        this.state.playerTurn === "Blue"
      ) {
        let validMove = -1;
        while (validMove === -1) {
          let slat = Math.floor(Math.random() * 7);
          if (this.state.boardState[slat].indexOf(null) !== -1) {
            validMove = slat;
          } else {
            validMove = -1;
          }
        }
        this.makeMove(validMove);
      }
    }
  }

  render() {
    let winnerMessageStyle;
    if (this.state.winner !== "") {
      winnerMessageStyle = "winnerMessage appear";
    } else {
      winnerMessageStyle = "winnerMessage";
    }
    let slats = [...Array(this.state.boardState.length)].map((x, i) => (
      <Slat
        key={i}
        holes={this.state.boardState[i]}
        handleClick={() => this.handleClick(i)}
      ></Slat>
    ));

    return (
      <div>
        {this.state.gameSelected && <div className="Board">{slats}</div>}
        <div className={winnerMessageStyle}>{this.state.winner}</div>

        <div>
          <button
            className="conect4-button"
            onClick={(e) => this.selectedGame("multi", e)}
          >
            {this.props.multipl}
          </button>
          <button
            className="conect4-button"
            onClick={(e) => this.selectedGame("single", e)}
          >
             {this.props.singlepl}
          </button>
        </div>
      </div>
    );
  }
}

function Connectfour() {
  const { t } = useTranslation();
  return (
    <motion.div
      className="base container"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <div className="Connect4-wrapper">
        <div className="Game">
          <div>
          <ReklamaHorizontal />
          <ReklamaHorizontalMob />
          </div>
          <Board singlepl={t('Single player')} multipl={t('Multi player')}></Board>
          <div>
          <ReklamaVetikal />
          </div>
        </div>
        <ShareLinks />
      </div>
    </motion.div>
  );
}

function checkLine(a, b, c, d) {
  return a !== null && a === b && a === c && a === d;
}

function checkWinner(bs) {
  for (let c = 0; c < 7; c++)
    for (let r = 0; r < 4; r++)
      if (checkLine(bs[c][r], bs[c][r + 1], bs[c][r + 2], bs[c][r + 3]))
        return bs[c][r] + " wins!";

  for (let r = 0; r < 6; r++)
    for (let c = 0; c < 4; c++)
      if (checkLine(bs[c][r], bs[c + 1][r], bs[c + 2][r], bs[c + 3][r]))
        return bs[c][r] + " wins!";

  for (let r = 0; r < 3; r++)
    for (let c = 0; c < 4; c++)
      if (
        checkLine(
          bs[c][r],
          bs[c + 1][r + 1],
          bs[c + 2][r + 2],
          bs[c + 3][r + 3]
        )
      )
        return bs[c][r] + " wins!";

  for (let r = 0; r < 4; r++)
    for (let c = 3; c < 6; c++)
      if (
        checkLine(
          bs[c][r],
          bs[c - 1][r + 1],
          bs[c - 2][r + 2],
          bs[c - 3][r + 3]
        )
      )
        return bs[c][r] + " wins!";

  return "";
}

export default Connectfour;
