import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MailIcon from "@material-ui/icons/Mail";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Navbar from "../components/Navbar";
import Home from "../pages/Home";
import About from "../pages/About";
import Connectfour from "../pages/Connectfour";
import Tictactoe from "../pages/TicTacToe";
import Squares from "../pages/Squares";
import Mastermind from "../pages/Mastermind";
import Minesweeper from "../pages/Minesweeper";
import Tetris from "../pages/Tetris";
import Mobile from "../pages/Mobile";
import Privacy from "../pages/Privacy";
import ConnectCards from "../pages/ConnectCards";
import { Switch, Route, Link, useLocation } from "react-router-dom";
import Button from "@material-ui/core/Button";
import tetrisimg from "../images/games/tetris.png";
import tictactoeimg from "../images/games/tictactoe.png";
import connect4img from "../images/games/connect4.png";
import mastermindimg from "../images/games/mastermind.png";
import squaresimg from "../images/games/squares.png";
import cardsimg from "../images/games/cards.png";
import minesweeperimg from "../images/games/minesweeper.png";
import RubberBand from "react-reveal/RubberBand";
import Zoom from "react-reveal/Zoom";
import { AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import FB from "@material-ui/icons/Facebook";
import IN from "@material-ui/icons/Instagram";
import apple from "../images/apple-white.png";
import play from "../images/playstore.png";

const drawerWidth = 301;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("xl")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    [theme.breakpoints.up("xl")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth
    }
  },
  menuButton: {
    marginRight: theme.spacing(1),
    padding: 0,
    minWidth: 25,
    [theme.breakpoints.up("xl")]: {
      display: "none"
    }
  },
  menuButtonClose: {
    marginRight: theme.spacing(1),
    padding: 0,
    minWidth: 25,
    position: 'absolute',
    right: 5,
    [theme.breakpoints.up("xl")]: {
      display: "none"
    }
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1)
  },
  toolbarLogo: {
    margin: 0,
    color: "red"
  },
  sideLinks: {
    textDecoration: "none",
    color: "#000",
    textTransform: 'uppercase',
    fontSize: 16
  },
}));

function ResponsiveDrawer(props) {
  const { t } = useTranslation();
  const location = useLocation();
  const { container } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <div className="toolbarBox">
        <div className={classes.toolbar}>
          <h4 className="toolbarLogo">
            <a href="https://www.facebook.com/Logicgamesapp-100475681829179" target="_blank"><FB></FB></a>
            <a href="https://www.instagram.com/logicgames.app/" target="_blank"><IN></IN></a>
          </h4>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButtonClose}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <Divider />
      <Zoom>
      <div className="all-games">
        <h1>All Games</h1>
      </div>
      <List>
        {/* <Link className={classes.sideLinks} to="/puzzle">
          <ListItem button>
            <ListItemIcon>
              <img src={tetrisimg} className="Menu-icon" />
            </ListItemIcon>
            <ListItemText>{t('Tetris')}</ListItemText>
          </ListItem>
        </Link> */}
        <Link className={classes.sideLinks} to="/conncet-four">
          <ListItem button>
            <ListItemIcon>
            <img src={connect4img} className="Menu-icon" />
            </ListItemIcon>
            <ListItemText>{t('Connect4')}</ListItemText>
          </ListItem>
        </Link>
        <Link className={classes.sideLinks} to="/tic-tac-toe">
          <ListItem button>
            <ListItemIcon>
              <img src={tictactoeimg} className="Menu-icon" />
            </ListItemIcon>
            <ListItemText>{t('Tic tac toe')}</ListItemText>
          </ListItem>
        </Link>
        <Link className={classes.sideLinks} to="/squares">
          <ListItem button>
            <ListItemIcon>
             <img src={squaresimg} className="Menu-icon"/>
            </ListItemIcon>
            <ListItemText>{t('Squares')}</ListItemText>
          </ListItem>
        </Link>
        <Link className={classes.sideLinks} to="/mastermind">
          <ListItem button>
            <ListItemIcon>
              <img src={mastermindimg} className="Menu-icon" />
            </ListItemIcon>
            <ListItemText>{t('Mastermind')}</ListItemText>
          </ListItem>
        </Link>
        <Link className={classes.sideLinks} to="/cards">
          <ListItem button>
            <ListItemIcon>
              <img src={cardsimg} className="Menu-icon" />
            </ListItemIcon>
            <ListItemText>{t('Connect cards')}</ListItemText>
          </ListItem>
        </Link>
        <Link className={classes.sideLinks} to="/minesweeper">
          <ListItem button>
            <ListItemIcon>
              <img src={minesweeperimg} className="Menu-icon" />
            </ListItemIcon>
            <ListItemText>{t('Minesweeper')}</ListItemText>
          </ListItem>
        </Link>
        
      </List>
      </Zoom>
      <div style={{display: 'none'}}>
          <img src="https://via.placeholder.com/300x250?text=Visit+Blogging.com+Now" />
      </div>
      <div className="side-app">
       <div className="Donwload-wrapper">
          <a
            href="https://apps.apple.com/us/app/logic-puzzle-games/id1536565555"
            target="_blank"
            className="Download-ios"
          >
            <div>
              <img src={apple} className="Apple-icon" />
            </div>
            <div className="Download-title">
              <span>{t("Download")}</span>
              <span>App Store</span>
            </div>
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.logicgames.gamesapp"
            target="_blank"
            className="Download-android"
          >
            <div>
              <img src={play} className="Playstore-icon" />
            </div>
            <div className="Download-title">
              <span>{t("Download")}</span>
              <span>Google Play</span>
            </div>
          </a>
        </div>
      </div>
    </div> 
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Zoom>
          <Typography className="Headertitle" variant="h6" noWrap>
          <Link className="HeaderTitleLink"  to="/">{t('Header title')}</Link>
          </Typography>
          </Zoom>
          <Navbar />
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden xlUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden lgDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <AnimatePresence exitBeforeEnter>
        <Switch location={location} key={location.key}>
          <Route path="/privacy">
            <Privacy />
          </Route>
          <Route path="/puzzle">
            <Tetris />
          </Route>
          <Route path="/cards">
            <ConnectCards />
          </Route>
          <Route path="/conncet-four">
            <Connectfour />
          </Route>
          <Route path="/tic-tac-toe">
            <Tictactoe />
          </Route>
          <Route path="/squares">
            <Squares />
          </Route>
          <Route path="/mastermind">
            <Mastermind />
          </Route>
          <Route path="/minesweeper">
            <Minesweeper />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/mobile">
            <Mobile />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
        </AnimatePresence>
      </main>
    </div>
  );
}

ResponsiveDrawer.propTypes = {
  container: PropTypes.any
};

export default ResponsiveDrawer;
