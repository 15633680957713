import styled from 'styled-components';

export const StyledTetrisWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const StyledTetris = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 0 auto;
  max-width: 900px;

  aside {
    width: 100%;
    max-width: 200px;
    display: block;
    padding: 0 20px;
  }
  @media (max-width: 1100px) {
    justify-conntent: space-around;
    align-items: stretch;
    aside {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
  @media (max-width: 640px) {
    flex-direction: row;
    justify-conntent: space-around;
    align-items: stretch;
    aside {
      max-width: 25vw;
      padding: 0;
    }
    aside > div{
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }
    aside > div > div{ 
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
    }
    aside > div > button{ 
      flex: 1;
      max-width: 100%;
      min-width: 100%;
    }
  }
`;
