import React, { useState } from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import ShareLinks from "../components/ShareLinks";
import TinderCard from "react-tinder-card";
import tetrisimg from "../images/mobile/tetris.png";
import tictactoeimg from "../images/mobile/tictactoe.png";
import connect4img from "../images/mobile/connect4.png";
import mastermindimg from "../images/mobile/mastermind.png";
import squaresimg from "../images/mobile/squares.png";
import cardsimg from "../images/mobile/card.png";
import minesweeperimg from "../images/mobile/minesweeper.png";
import qrapple from "../images/gamesios.png";
import qrplay from "../images/gamesandroid.png";
import apple from "../images/apple-white.png";
import play from "../images/playstore.png";

const containerVariants = {
  hidden: {
    opacity: 0,
    x: "100vw",
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: { type: "spring", delay: 0.1 },
  },
  exit: {
    x: "-100vh",
    transition: { ease: "easeInOut" },
  },
};

export default function Mobile() {
  const { t } = useTranslation();
  const outOfFrame = (name) => {};
  return (
    <motion.div
      className="base container"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <div className="Mobile-container">
        <h1>{t("Mobile title")}</h1>
        <div className="Mobile-box">
          <div>
            <div className="qr-code">
              <img
                src={qrapple}
                className="qr-code-img"
                alt="duas form the Quran android"
              />
              <img
                src={qrplay}
                className="qr-code-img"
                alt="duas form the Quran ios"
              />
            </div>
            <div className="Donwload-wrapper">
              <a
                href="https://apps.apple.com/us/app/logic-puzzle-games/id1536565555"
                target="_blank"
                className="Download-ios"
              >
                <div>
                  <img src={apple} className="Apple-icon" />
                </div>
                <div className="Download-title">
                  <span>{t("Download")}</span>
                  <span>App Store</span>
                </div>
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.logicgames.gamesapp"
                target="_blank"
                className="Download-android"
              >
                <div>
                  <img src={play} className="Playstore-icon" />
                </div>
                <div className="Download-title">
                  <span>{t("Download")}</span>
                  <span>Google Play</span>
                </div>
              </a>
            </div>
          </div>
          <div className="cardContainer">
            <TinderCard
              className="swipe"
              key="Squares"
              onCardLeftScreen={() => outOfFrame("Squares")}
              preventSwipe={["right", "left", "up", "down"]}
            >
              <div
                style={{ backgroundImage: "url(" + squaresimg + ")" }}
                className="card"
              >
                <h3>Squares </h3>
              </div>
            </TinderCard>
            <TinderCard
              className="swipe"
              key="Cards"
              onCardLeftScreen={() => outOfFrame("Cards")}
            >
              <div
                style={{ backgroundImage: "url(" + cardsimg + ")" }}
                className="card"
              >
                <h3>Cards </h3>
              </div>
            </TinderCard>
            <TinderCard
              className="swipe"
              key="Connect4"
              onCardLeftScreen={() => outOfFrame("Connect4")}
            >
              <div
                style={{ backgroundImage: "url(" + connect4img + ")" }}
                className="card"
              >
                <h3>Connect 4 </h3>
              </div>
            </TinderCard>
            <TinderCard
              className="swipe"
              key="Tictactoe"
              onCardLeftScreen={() => outOfFrame("Tictactoe")}
            >
              <div
                style={{ backgroundImage: "url(" + tictactoeimg + ")" }}
                className="card"
              >
                <h3>Tic tac toe </h3>
              </div>
            </TinderCard>
            <TinderCard
              className="swipe"
              key="Minesweeper"
              onCardLeftScreen={() => outOfFrame("Minesweeper")}
            >
              <div
                style={{ backgroundImage: "url(" + minesweeperimg + ")" }}
                className="card"
              >
                <h3>Minesweeper </h3>
              </div>
            </TinderCard>
            <TinderCard
              className="swipe"
              key="Mastermind"
              onCardLeftScreen={() => outOfFrame("Mastermind")}
            >
              <div
                style={{ backgroundImage: "url(" + mastermindimg + ")" }}
                className="card"
              >
                <h3>Mastermind</h3>
              </div>
            </TinderCard>
          </div>
        </div>
      </div>
      <ShareLinks />
    </motion.div>
  );
}
