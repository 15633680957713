import React, { useEffect, useState } from "react";
import { StyledCell } from "./styles/StyledCell";
import { TETROMINOS } from "./tetrominos";

// const triangleStyle = (col) => ({
//   width: 'auto',
//   background: col,
//   border: type === 0 ? '0px solid' : '4px solid',
//   borderBottomColor: col,
//   borderRightColor: col,
//   borderTopColor: col,
//   borderLeftColor: col,
// })

const Cell = ({ type }) => {
  

  return (
    <StyledCell type={type} color={TETROMINOS[type].color}>
      {console.log('rerender cell')}
    </StyledCell>
  )

  // return (
  //   <div
  //     style={triangleStyle(TETROMINOS[type].color)}
  //     type={type}
  //   >
  //     {console.log("rerender cell")}
  //   </div>
  // );
};

export default React.memo(Cell);
