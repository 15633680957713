import styled from 'styled-components';

export const StyledDisplay = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin: 0 0 15px 0;
  padding: 0 15px;
  border: 1px solid #ebebeb;
  min-height: 40px;
  height: 40px;
  width: 100%;
  border-radius: 5px;
  color: ${props => (props.gameOver ? 'red' : '#333')};
  background: #f2f2f2;
  font-family: Pixel, Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 16px;
  @media (max-width: 640px) {
    font-size: 14px;
    line-height: 14px;
    padding: 0 10px;
    margin: 0 0 10px 0;
    min-height: 35px;
    height: 35px;
  }
`;
