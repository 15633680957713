import React, {useState} from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Home from './pages/Home';
import About from './pages/About';
import Navbar from './components/Navbar';
import ResponsiveDrawer from './layouts/ResponsiveDrawer';

export default function App() {
  
  return (
    <Router>
      <div className="App">
        <ResponsiveDrawer />
      </div>
    </Router>
  );
}
