import React from 'react';

export class Rules extends React.Component {
  constructor(props) {
    super(props)
    this.toogleRules = this.toogleRules.bind(this)
    this.state = {
      show: true,
    }   
  }

  toogleRules() {
    this.setState({
      show: this.state.show ? false : true,
    })
  }

  render() {
    const link = 'https://en.wikipedia.org/wiki/Mastermind_(board_game)';
    const rulesTitle = this.state.show ? this.props.showTitle : this.props.hideTitle;
    const style = {
      display: this.state.show ? 'none' : 'block',
    }

    return (
      <div className='mastermind-rules'>
        <h3 onClick={this.toogleRules}> {rulesTitle} </h3>
        <p style={style}> {this.props.rules} <br />
        More info on <a href={link}>Wikipedia</a>.
        </p>
      </div>
    )
  }
}